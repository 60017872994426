function setEqualHeight() {
    current_breakpoint_num = getNumSkelSize();
    $(".grid-product .grid-element-content-header-image").matchHeight();
    $(".grid-product .product-name-wrapper").matchHeight();
    $(".grid-product .product-options").matchHeight();
    $(".grid-product .productshortattributes").matchHeight();
    $(".grid-product .nakup").matchHeight();
    $(".grid-product").matchHeight({byRow:false});
    $(".news-image-wrapper").matchHeight();
    $(".news-content-wrapper").matchHeight();
    $(".locations .grid-element-content").matchHeight();
    $(".grid-menu-item-019 .image-wrapper, .grid-menu-item-019 .content-wrapper").matchHeight();
    if (current_breakpoint_num > 1) {
        $("#footer-wrapper .grid-element-content").matchHeight();
    } else {
        $("#footer-wrapper .grid-element-content").matchHeight({remove: true});
}

}
$(window).on("load",function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(function() {
    $("#main-menu-mobile").smartmenus({
        subMenusSubOffsetX: 1,
        subMenusSubOffsetY: -8,
        keepInViewport: true,
        keepHighlighted: false,
        markCurrentItem: false,
        markCurrentTree: false
        /*}).bind("click.smapi", function(e, item) {
            var $item = $(item);
            if($item.attr("href") === "#") {
                e.preventDefault();
                $('#main-menu').smartmenus('itemActivate', $item);
                return false;
            }*/
    });
});

$('#main-menu-state-mobile').change(function(e) {
    var $menu = $('#main-menu-mobile');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu-mobile").offset().top - $(window).scrollTop()));
                $('#main-menu-mobile').css("max-height", maxHeight + "px");
                if ($('#main-menu-mobile').outerHeight() > maxHeight) {
                    $('#main-menu-mobile').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu-mobile').css("max-height", "");
            $('#main-menu-mobile').css("height", "");
        });
    }
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit = 250;
    switch (current_breakpoint) {
        case "xxxlarge":
            scroll_limit = 150;
            break;
        case "xxlarge":
            scroll_limit = 150;
            break;
        case "xlarge":
            scroll_limit = 110;
            break;
        case "large":
            scroll_limit = 170;
            break;
        case "medium":
            scroll_limit = 50;
            break;
        case "small":
            scroll_limit = 50;
            break;
        case "xsmall":
            scroll_limit = 50;
            break;
        default:
            scroll_limit = 250;
            break;
    }
    if ($(window).scrollTop() >= scroll_limit) {
        $("#header-wrapper").addClass("fixed");
        $("#header-wrapper").css("margin-top", -scroll_limit + "px");
        } else {
            $("#header-wrapper").removeClass("fixed");
            $("#header-wrapper").css("margin-top", -$(window).scrollTop() + "px");
        }
    });
